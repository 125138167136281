<script setup lang="ts">
import { MessageEle } from '~/plugins/message.client'
import banner from '~/assets/image/search/banner@2x.png'
import empty_file from '~/assets/image/empty/empty_file@2x.png'
import { SearchApi } from '~/net/apis/search_api'

const value = ref('')
const showLoad = ref(false)
const showVideo = ref(false)
const isSearch = ref(true)
const router = useRouter()
const onClickLeft = () => history.back()
const list = reactive([
  '国产',
  '丝袜'
])
const hotList = ref<any[]>([])
async function getHotLists() {
  try {
    const data = await SearchApi.hotList()
    hotList.value = data.data
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}
await getHotLists()

const activeIndex = ref(-1)
const activeIndex1 = ref(-1)
function onSearch(val: any) {
  MessageEle.success(val)
}
const videoList = ref<any[]>([])
const pageNum = ref(1)
async function onClickButton() {
  isSearch.value = !isSearch.value
  if (isSearch.value) {
    showVideo.value = false
    value.value = ''
    videoList.value = []
    pageNum.value = 1
    activeIndex1.value = -1
    activeIndex.value = -1
    return
  }
  if (!value.value) {
    MessageEle.error('搜索内容不能为空')
  } else {
    showVideo.value = true
    updateArray(value.value)
  }
}
// <NuxtLink :to="`/player/${item?.videoId}`">
// 维护数组长度为4，并将指定值调整或插入到数组的第一个位置
function updateArray(newValue: any) {
  const targetValue = newValue.trim() // 获取输入的新值并去除空格
  if (list.includes(targetValue)) {
    // 如果值已存在，则调整到数组的第一个位置
    const index = list.findIndex(item => item === targetValue)
    if (index !== 0) {
      list.splice(index, 1) // 从当前位置移除
      list.unshift(targetValue) // 将值插入到第一个位置
    }
  } else {
    // 如果值不存在，则插入到数组的第一个位置
    list.unshift(targetValue)
  }

  // 维护数组长度为4
  if (list.length > 4) {
    list.splice(4) // 移除多余的元素
  }
}

function removeItem(index: number) {
  // 使用 splice 方法从数组中移除指定索引的元素
  list.splice(index, 1)
}
function clearAll() {
  list.splice(0, list.length) // 清空数组
}

async function toggleRed(index: number, item: any) {
  // 切换对应索引的 isRed 值
  activeIndex.value = index
  value.value = item
  pageNum.value = 1
  await onClickButton()
}

async function toggleRed1(index: number, item: any) {
  // 切换对应索引的 isRed 值
  activeIndex1.value = index
  value.value = item.hotName
  pageNum.value = 1
  await onClickButton()
}

function choose(item: any) {
  console.log('跳转影片：', item)
  router.push(`/player/${item?.videoId}`)
}
function formatSecondsToTime(seconds: number) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60

  // 使用 padStart 方法来确保小时、分钟和秒都是两位数
  const hoursStr = String(hours).padStart(2, '0')
  const minutesStr = String(minutes).padStart(2, '0')
  const secsStr = String(secs).padStart(2, '0')

  // 返回格式化后的时间字符串
  return `${hoursStr}:${minutesStr}:${secsStr}`
}
</script>

<template>
  <div h-screen overflow-hidden px-4>
    <!-- <div flex flex-row items-center> -->
      <van-nav-bar left-arrow @click-left="onClickLeft">
        <template #title>
          <van-search
          v-model="value"
          left-icon="-"
          placeholder="搜索您感兴趣的内容"
          w-67
          clear-trigger="always"
          @search="onSearch"
        />
        </template>
      <template #right>
        <div w-10 ct-ffffff @click="onClickButton">{{ isSearch ? '搜索' : '取消' }}</div>
      </template>
    </van-nav-bar>
    <!-- </div> -->
    <div v-if="list.length > 0" flex items-center justify-between>
      <div ct-ffffff>历史搜索</div>
      <div flex flex-row items-center text-xs ct-414660 @click="clearAll">
        <BaseIcon name="clear" size="1" />
        全部清除
      </div>
    </div>
    <div mt-4>
      <div v-if="list.length > 0" flex flex-row>
        <div v-for="(item, index) in list" :key="index" mr-2 h-6.5 min-w-18 items-center rounded-1 bg-242943 ct-ffffff>
          <div flex items-center justify-between>
            <span min-w-14 text-center :class="{ 'red-text': index === activeIndex }" @click="toggleRed(index, item)">{{
              item
            }}</span>
            <van-icon w-4 name="cross" size="10" @click="removeItem(index)" />
          </div>
        </div>
      </div>
    </div>
    <div mt-4 ct-ffffff>热门搜索</div>
    <div mt-4>
      <div v-if="hotList.length > 0" flex flex-row flex-wrap>
        <div
          v-for="(item, index) in hotList"
          :key="index"
          mb-2
          mr-2
          h-6.5
          min-w-18
          items-center
          rounded-1
          pl-2
          pr-2
          bg-242943
          ct-ffffff
        >
          <div text-center>
            <span :class="{ 'red-text': index === activeIndex1 }" @click="toggleRed1(index, item)">{{
              item.hotName
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div mt-4>
      <NuxtLink to="/ranking-list">
        <img :src="banner" />
      </NuxtLink>
    </div>
  </div>
  <LoadReflesh v-model:show="showLoad" />
  <van-overlay
    :show="showVideo"
    :lock-scroll="false"
    style="background: #111425; top: 46px; z-index: 9999; overflow: hidden; height: 100% ;"
    click="showVideo = false"
  >
      <PullRefreshListBottom
      wrap-class="w-full grid grid-cols-2 gap2 px-15px"
      :request-api="SearchApi.video"
      :offset-list="20"
      :is-empty="true"
      :request-params="{
        keyword: value
      }"
    >
      <template #="{ data }">
        <van-col v-for="(item, index) in data as Array<VideoModel>" :key="index" @click="choose(item)">
          <div relative>
            <ImgWrapper h-28 w-full :src="item?.videoHorizontalUrl" />
            <div absolute bottom-0 h-5 w-full pl-2 pr-2 text-right text-xs leading-5 ct-ffffff class="bg">
              {{ formatSecondsToTime(item?.videoDuration) }}
            </div>
          </div>
          <div overflow-hidden whitespace-nowrap text-sm ct-ffffff>
            {{ item?.videoName }}
          </div>
          <div text-sm ct-ffffff>番号：{{ item?.videoNo }}</div>
        </van-col>
      </template>
      <template #empty>
        <div h-2xl flex flex-col items-center justify-center px-4>
          <img h-33 w-50 :src="empty_file" />
          <div mt-3 text-xs ct-ffffff>主人，暂时没有相关内容</div>
        </div>
      </template>
 </PullRefreshListBottom>
  </van-overlay>
  <!-- <TheFooter fixed bottom-0 h-50 w-full /> -->
</template>

<style scoped>
:deep(.van-search) {
  background: #111425;
}
:deep(.van-pull-refresh){
  max-height: calc(100% - 6px);
  overflow: scroll;
}
:deep(.sroll-view::-webkit-scrollbar){
  width: 0.01rem;
}
:deep(.van-pull-refresh::-webkit-scrollbar){
  width: 0.03rem;
}
:deep(.van-nav-bar__title){
  max-width: 80%;
}
:deep(.van-search__content) {
  background: #090c1c;
  border-radius: 15px;
}
:deep(.van-field__control) {
  color: #e8e8e8;
}
:deep(.van-field__control::placeholder) {
  color: #414660;
}
.red-text {
  color: #fd5f03;
}
</style>
