import http from '~/utils/http'


export class SearchApi {
  // @POST('/jav/search/hotList')
  // Future<List<SearchConfigHot>> hotList();
  static hotList(opt?: HttpOptions) {
    return http.post<Array<SearchConfigHot>>({ ...opt, url: '/jav/search/hotList' })
  }

  // @POST('/jav/search/video')
  // Future<FilterVideoPageResponse> video(@Body() SearchVideoParam param);
  static video(params: SearchVideoParam, opt?: HttpOptions) {
    return http.post<FilterVideoPageResponse>({ ...opt, url: '/jav/search/video', body: params })
  }
}
